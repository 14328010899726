import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../components/layout';
import Footer from '../../components/footer';
import SlickSlider from '../../components/slick-slider-new';
import { CaseStudyCard } from '../../components/case-study-card';
import Button from '../../components/button';

const settings = {
    responsive: [
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 1
            }
        }
    ],
    dots: true,
    autoplaySpeed: 10000
};

const Page = ({ data }) => {

    const { allContentfulCaseStudy: caseStudies } = data;

    return <Layout>
        <div className="thank-you-for-downloading-page">
            <section className="bg-brand-primary-2 py-5 text-white">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-12 text-center">
                            <h2 className="mb-0 pb-0">Thank you for downloading!</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-brand-neutral-2 py-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-12 ps-5 text-center">
                            <p className="lead m-0">Your email should arrive shortly. In the meantime, here are some case studies you might be interested in</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-5">
                <div className="container">
                    <div className="row align-items-stretch g-3 g-lg-5 d-none d-md-flex">
                        {caseStudies.nodes
                            .slice(0, 3)
                            .map(({ title, path, brandLogo }, index) => <CaseStudyCard key={index} title={title} id={path} size="small" logo={brandLogo} />)}
                    </div>
                    <div className="d-md-none">
                        <SlickSlider customSettings={settings}>
                            {caseStudies.nodes
                                .slice(0, 3)
                                .map(
                                    ({
                                        title,
                                        path
                                    }, index) => <CaseStudyCard key={index} title={title} id={path} size="small" />)}
                        </SlickSlider>
                    </div>
                    <div className="text-center mt-5">
                        <Button
                            type="link"
                            size="large"
                            href={`/insights/case-studies`}
                            className="btn btn-primary"
                        >
                            See all case studies
                        </Button>
                    </div>
                </div>
            </section>
            <section className="mt-5">
                <div className="bg-primary py-3 py-md-5 text-white">
                    <div className="container text-center">
                        <h2 className="fw-bold display-5">
                            Ready to discuss?
                        </h2>
                        <Button size="medium" type="polymath-link" to="/contact" className="btn btn-brand-cta-1">
                            Contact Us
                        </Button>
                    </div>
                </div>
            </section>

        </div>
        <Footer />
    </Layout>;
};

export default Page;

export const query = graphql`
    query {
        allContentfulCaseStudy {
            nodes {
                id
                path
                title
                brandLogo {
                    gatsbyImageData
                }
                atGlance {
                    atGlance
                }
            }
        }
    }
`;
